import React, { useEffect, useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import cameraIcon from '../../assets/images/sc/kamera.png';
import cameraIconWebp from '../../assets/images/sc/kamera.webp';
// import websiteLogo from '../../assets/images/pixelatedlogo.png';
import specsIcon from '../../assets/images/sc/specs.png';
import specsIconWebp from '../../assets/images/sc/specs.webp';
import steamIcon from '../../assets/images/sc/steam.png';
import steamIconWebp from '../../assets/images/sc/steam.webp';
// import cuteIcon from '../../assets/images/sc/Reatiste.png';
import pixelIcon from '../../assets/images/sc/Zengta.png';
import pixelIconWebp from '../../assets/images/sc/Zengta.webp';
import SetupAvatar from '../../assets/images/setup.jpg';
import SetupAvatarWebp from '../../assets/images/setup.webp';
import classes from './Links.module.css';

export const Links = () => {
  const ICON_COLOR = '#fff';
  const links = [
    {
      name: 'Twitch',
      url: 'https://www.twitch.tv/setupstreaming',
      bgColor: '#6441a5',
      network: 'twitch',
    },
    {
      name: 'YouTube',
      url: 'https://youtube.com/@SetupStreaming',
      bgColor: '#ff3333',
      network: 'youtube',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/setup_media/',
      bgColor: '#3f729b',
      network: 'instagram',
    },
    {
      name: 'Twitter',
      url: 'https://www.twitter.com/setupgaming',
      bgColor: '#00aced',
      network: 'twitter',
    },
    {
      name: 'Specs',
      url: 'https://goo.gl/85UuWU',
      bgColor: '#34a853',
      src: specsIcon,
      webp: specsIconWebp,
    },
    {
      name: '3D-Printing',
      url: 'https://photos.app.goo.gl/oFUaGc4yK8taktxi9',
      bgColor: '#E05D2D',
      src: cameraIcon,
      webp: cameraIconWebp,
    },
    {
      name: 'Mini-Painting',
      url: 'https://photos.app.goo.gl/cD3n5jYy4KN4z25XA',
      bgColor: '#fc0fd1',
      src: cameraIcon,
      webp: cameraIconWebp,
    },
    // {
    //   name: "Setup's Projects",
    //   url: 'https://setup-coding.de/',
    //   bgColor: '#151c2e',
    //   src: websiteLogo,
    // },
    {
      name: 'Pixel Mixer',
      url: 'https://setup-coding.de/overwatchpixelmixer',
      bgColor: '#151c2e',
      src: pixelIcon,
      webp: pixelIconWebp,
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/SetupGaming',
      bgColor: '#3b5998',
      network: 'facebook',
    },
    {
      name: 'VK',
      url: 'https://vk.com/SetupGaming',
      bgColor: '#45668e',
      network: 'vk',
    },
    {
      name: 'Steam',
      url: 'https://steamcommunity.com/id/SetupGaming',
      bgColor: '#000',
      src: steamIcon,
      webp: steamIconWebp,
    },
    // {
    //   name: 'Cute Mixer',
    //   url: 'https://setup-coding.de/overwatchcutemixer',
    //   bgColor: '#151c2e',
    //   src: cuteIcon,
    // },
  ];
  const [isFontLoading, setIsFontLoading] = useState(true);

  useEffect(() => {
    document.body.className = classes.linkBody;

    return () => {
      document.body.className = '';
    };
  }, []);

  document.fonts.ready.then(() => {
    // loading fonts is done
    setIsFontLoading(false);
  });

  return (
    <div className={classes.SocialIconList}>
      <div className={classes.userArea}>
        <span className={classes.imgCircle}>
          <a href="https://www.twitch.tv/setupstreaming" rel="noopener noreferrer" target="_blank">
            <picture>
              <source srcSet={SetupAvatarWebp} alt="Setup's Avatar" type="image/webp" />
              <img className={classes.userImg} width="96" height="96" alt="Setup's Avatar" src={SetupAvatar} />
            </picture>
          </a>
        </span>
      </div>
      {links.map((link, index) => (
        <a
          key={link.url}
          href={link.url}
          className={classes.anchor}
          style={{ backgroundColor: link.bgColor, animationDelay: `0.${Math.round(index / 2)}s` }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={classes.inlineButton}>
            {link.src ? (
              <div className={classes.socialIconRebuild} style={{ backgroundColor: link.bgColor }}>
                <picture>
                  <source srcSet={link.webp} alt={link.name} type="image/webp" />
                  <img src={link.src} alt={link.name} />
                </picture>
              </div>
            ) : (
              <SocialIcon
                style={{ height: 80, width: 80, float: 'left', marginTop: -12 }}
                fgColor={ICON_COLOR}
                bgColor={link.bgColor}
                network={link.network ?? undefined}
                label={link.name}
                // url={link.url}
              ></SocialIcon>
            )}
          </div>
          <div className={`${classes.linkText} ${isFontLoading ? classes['linkText--loading'] : ''}`}>{link.name}</div>
        </a>
      ))}
    </div>
  );
};
